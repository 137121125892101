import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Хэлцлийн түүх",
  "description": null,
  "author": "OTC help",
  "category": "my-deals",
  "date": "2022-04-08T00:00:00.000Z",
  "featured": true,
  "tags": [],
  "order": 3
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Хэлцлийн түүх`}</h2>
    <ol>
      <li parentName="ol">{`Үндсэн цэсний `}<strong parentName="li">{`[My Deals]`}</strong>{` дэх `}<strong parentName="li">{`[Primary History]`}</strong>{` хэсгээс оролцогч өөрийн оролцсон анхдагч зах зээлийн арилжааны түүх, `}<strong parentName="li">{`[Secondary History]`}</strong>{` хэсгээс оролцсон хоёрдогч зах зээлийн арилжааны түүхийн жагсаалтыг арилжаа тус бүрийн дэлгэрэнгүй мэдээлэл болон `}<strong parentName="li">{`[OTC Status]`}</strong>{` буюу платформ дахь төлөв, `}<strong parentName="li">{`[CSD Status]`}</strong>{` буюу ҮЦТХТ дэх төлөвийн хамт тус тус харах боломжтой. Тухайлсан үнэт цаасны арилжааны түүхийг шүүж харах бол жагсаалтын хайлтын хэсэгт үнэт цаасны нэрийг оруулан хайлт хийнэ.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      